import { lastUpdateThresholdMS } from "../../consts/DataConfig"

export const isHaveToFetchNewData = ({ lastUpdated = Date.now() }) => {
  return Date.now() - lastUpdated > lastUpdateThresholdMS
}

export const getDataByKey = (key, state) => {
  let data = state.organizationData;
  return data && data[key];
}

export const getCurrentDataByKey = (key, state) => {
  let data = state.organizationData;
  return data && data[key] && data[key].data;
}