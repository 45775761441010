import React from 'react';
import { Switch, Route } from 'react-router';
import { ROUTES } from '../../consts';
import { PrivateRoute } from '../router/PrivateRoute';
import { AuthRoute } from '../router/AuthRoute';
import { SignInPage } from '../../pages/auth/SignIn.page';
import { Frame } from '../core';
import { InsightsViaEmail } from '../../pages/insightsViaEmail/InsightsViaEmail.page';
import { ScenarioDashboard } from '../../pages/scenario-dashboard';

export const Router = React.memo(() => {
  return (
    <Switch>
      <AuthRoute exact path={ROUTES.SIGN_IN} component={SignInPage} />

      <PrivateRoute path={ROUTES.ROOT} component={Frame}>
        <Route exact path={ROUTES.ROOT} component={InsightsViaEmail} />
        <Route exact path={ROUTES.SCENARIO_DASHBOARD} component={ScenarioDashboard} />
      </PrivateRoute>


      <Route component={() => (<div>Something went wrong</div>)} />
    </Switch>
  )
})