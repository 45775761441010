import HypercastAPI from '../api/HypercastAPI';
import { store } from '../store/store';
import InsightsViaEmailAPI from '../api/InsightsViaEmailAPI';
import { logout } from '../store/actions/User.actions';

class UserService {
  constructor() {
    this.logout = this.logout.bind(this)
  }

  get state() {
    return store.getState().auth
  }

  get orgId() {
    return this.state.user.organizationId
  }

  async signIn(credentials) {
    let { data, success, error } = await HypercastAPI.login(credentials);

    if (!success) {
      if (error.message === 'Request failed with status code 401') {
        throw new Error('Fail to signin')
      }
      throw new Error(error)
    }
    let { token } = data;

    this._setToken(token)

    let user = await this.me()
    console.log(user)

    return { user, token };
  }

  async me() {
    let userDetailRes = await HypercastAPI.me();

    if (userDetailRes.error) {
      return { error: 'Something went wrong...' };
    }

    let { user } = userDetailRes.data;

    user.organizationId = user.accountId;

    return user;
  }

  init() {
    if (this.accessToken && this.isTokenExpired) {
      this.logout();
    }

    if (!this.accessToken) {
      return false;
    }

    // Add token
    this._setToken(this.state.token);
  }

  logout() {
    store.dispatch(logout());
    this._setToken(null);
  }

  // ========== Auth methods =============
  get accessToken() {
    let { token } = this.state;
    return token && token;
  }

  get isTokenExpired() {
    let { tokenDetails } = this.state;
    if (tokenDetails && tokenDetails.exp) {
      return 1000 * tokenDetails.exp - Date.now() < 5000;
    }
    return true;
  }

  _setToken(token) {
    HypercastAPI.setToken(token)
    InsightsViaEmailAPI.setToken(token);
  }

  get isAuthenticated() {
    return !this.isTokenExpired;
  }
}

export default new UserService();