import React from 'react';
import { connect } from 'react-redux';
// import styles from './InsightsViaEmail.module.scss';
import { Page } from '../../components/core/page/Page.core';
import { InsightsViaEmailManager } from '../../components/insightsViaEmail/InsightsViaEmail-manager';

const InsightsViaEmailComponent = React.memo(({ lang }) => {
  return (
    <Page header={lang.HEADER}>
      <InsightsViaEmailManager />
    </Page>
  )
})

const mapStateToProps = state => ({
  lang: state.lang.INSIGHT_VIA_EMAIL_PAGE
})

export const InsightsViaEmail = connect(mapStateToProps)(InsightsViaEmailComponent)