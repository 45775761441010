import { Popover } from 'antd';
import React from 'react';
import { Button } from '../../components/core/buttons/Button';
import { CSVReader } from '../../components/csv-reader/csv-reader.component';
import { TimelineWrapper } from '../../components/timelines/timeline-wrapper/timeline-wrapper.component';
import { FORECAST_TYPES } from '../../consts/forecasts.constants';
import { Lang } from '../../consts/Lang';
import { InsightsDashboards } from '../../images';
import { forecastConvertor } from '../../services/forecast-convertors/forecast-convertor';
import { calculateTimelines } from '../../services/insights/calculate-timeline';
import styles from './scenario-dashboard.module.scss';
import {
  DownOutlined
} from '@ant-design/icons';
import _ from 'lodash';

const allDashboard = {
  name: 'All Insights',
  id: '*'
}

const ScenarioDashboardComponent = React.memo(function ScenarioDashboardComponent({ lang, insights, getOrganizationInsights, getOrganizationDashboards, initialForecast, dashboards }) {
  const [forecast, setForecast] = React.useState(initialForecast)
  const [timelines, setTimelines] = React.useState([]);
  const [dashboard, setDashboard] = React.useState(allDashboard)
  const [activeInsights, setActiveInsights] = React.useState([])

  const calculateTimelinesAction = React.useCallback(() => {
    if (!forecast) return null

    let timelines = calculateTimelines({
      insights: activeInsights,
      forecast,
    });

    setTimelines(timelines)
  }, [forecast, activeInsights])

  const importCSV = React.useCallback(({ list }) => {
    setForecast(forecastConvertor({ forecast: list, type: FORECAST_TYPES.METAR }))
  }, [])

  React.useEffect(() => {
    calculateTimelinesAction();
  }, [forecast, activeInsights])

  React.useEffect(() => {
    getOrganizationInsights();
    getOrganizationDashboards();

    if (initialForecast) calculateTimelinesAction();
  }, [])

  React.useEffect(() => {

    if (allDashboard.id === dashboard.id) {
      setActiveInsights(insights)
      return
    }
    let insightsIds = _.map(_.uniqBy(dashboard.items, 'insightId'), 'insightId');
    let insightsToSet = insights.filter(({ id }) => insightsIds.includes(id));
    setActiveInsights(insightsToSet)
  }, [dashboard, insights])

  if (!forecast) {
    return (
      <div className={styles.noTasks}>
        <img src={InsightsDashboards} alt={lang.EMPTY.TITLE} className={styles.noTasksImage} />
        <h1 className={styles.header}>{lang.EMPTY.TITLE}</h1>
        <p className={styles.content}>{lang.EMPTY.CONTENT}</p>

        <h4 className={styles.upload}>{lang.UPLOAD_FORECAST}</h4>
        <CSVReader onLoad={importCSV} />
      </div>
    )
  }

  const renderDashboardList = () => {
    return (
      <div className={styles.dashboards}>
        <button
          onClick={() => setDashboard(allDashboard)}
          className={styles.dashboardItem}>
          {allDashboard.name}
        </button>
        {dashboards.map((dashboard) =>
          <button
            onClick={() => setDashboard(dashboard)}
            className={styles.dashboardItem}>
            {dashboard.name}
          </button>
        )}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <h1>{lang.TITLE}</h1>
      <div className={styles.header}>
        <div className={styles.selectDashboard}>
          <Popover
            placement="bottomLeft"
            content={renderDashboardList}
            trigger="click"
            overlayClassName={styles.dashboardsWrapper}>
            <h3>{dashboard.name} <DownOutlined className={styles.dashboardArrow} /></h3>
          </Popover>
        </div>
        <div className={styles.buttons}>
          <Button>{lang.BUTTONS.EDIT_INSIGHTS}</Button>
          <Button>{lang.BUTTONS.EDIT_LOCATIONS}</Button>
          <Button>{lang.BUTTONS.MORE}</Button>
          <Button buttonStyle="primary">{lang.BUTTONS.NEW_DASHBOARD}</Button>
        </div>

      </div>
      <div className={styles.uploadNewForecast}>
        <h4 className={styles.upload}>{lang.UPLOAD_FORECAST}</h4>
        <CSVReader onLoad={importCSV} />
      </div>

      <TimelineWrapper
        timelines={timelines}
        startTime={forecast?.startTime}
        endTime={forecast?.endTime} />
    </div>
  )
})

ScenarioDashboardComponent.defaultProps = {
  lang: Lang.SCENARIO_DASHBOARD,
  insights: [],
  getOrganizationInsights: () => { },
  getOrganizationDashboards: () => { },
  initialForecast: null,
  dashboards: []
}

export { ScenarioDashboardComponent }