export const OPERATORS = {
  AND: 'AND',
  OR: 'OR',
  GREATER: 'GREATER',
  GREATER_EQUAL: 'GREATER_EQUAL',
  LESS: 'LESS',
  LESS_EQUAL: 'LESS_EQUAL',
  EQUAL: 'EQUAL',
  IS_NULL: 'IS_NULL',

  // TODO: add operators
  TIME_IN_DAY: 'TIME_IN_DAY',
  DURATION: 'DURATION',
}