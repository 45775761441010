import { operationCalculate } from "./operators-calculate";

export function calculateTimelines({ forecast = [], insights = [] }) {
  // Foreach insights
  let timelines = insights.map(insight =>
    calculateTimelinePerInsight({ forecast, insight })
  )

  return timelines
}

export function calculateTimelinePerInsight({ insight = {}, forecast = {} }) {
  let conditions = insight.conditions
  let timeline = []
  let success = false;

  try {
    timeline = forecast.intervals.map(observation => ({
      state: calculateInsightResult({ observation, conditions }),
      startTime: observation.startTime
    }))
    success = true;
    compressTimeline({ timeline })
  } catch (e) {
    // console.log(e.message)
  }

  return {
    id: insight.id,
    insight,
    timeline,
    success,
  }
}

export function compressTimeline({ timeline }) {
  let state = timeline[timeline.length - 1].state;
  let stateCounter = 0;

  for (let i = timeline.length - 1; i >= 0; --i) {
    if (i === 0) {
      timeline[i].endTime = timeline[stateCounter].startTime;
      timeline.splice(i + 1, stateCounter + 1)
      timeline[i].duration = stateCounter + 2;
      timeline[i].index = i;
    } if (state === timeline[i].state) {
      stateCounter++;
    } else {
      timeline[i + 1].endTime = timeline[i + stateCounter] && timeline[i + stateCounter].startTime || timeline[i + stateCounter - 1].startTime;
      timeline.splice(i + 2, stateCounter - 1)
      timeline[i + 1].duration = stateCounter + 1;
      timeline[i + 1].index = i + 1;
      state = timeline[i].state;
      stateCounter = 0;
    }
  }
}

export function calculateInsightResult({ observation, conditions }) {
  try {
    return operationCalculate({ observation, condition: conditions })
  } catch (e) {
    console.error('Fail to calculate observation', { observation, conditions, error: e.message })
    throw e
  }
}
