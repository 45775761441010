import _ from 'lodash';
import { operationCalculate } from '../operators-calculate';

export function andOperator({ observation, children }) {
  // Check valid array
  if (!_.isArray(children)) {
    throw new Error('children not array')
  }

  for (let i = 0; i < children.length; i++) {
    if (!operationCalculate({
      observation,
      condition: children[i]
    })) {
      return false
    }
  }

  return true
}