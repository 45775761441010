import React from 'react';
import classNames from 'classnames';
import styles from './Message.module.scss';
import { STYLE_PRESET } from '../../../consts';

const Message = React.memo(({ children, className, style }) => {

  if (!children) {
    return null
  }

  return (
    <div className={classNames(styles.root, styles[style], className)}>
      {children}
    </div>
  )
})

Message.defaultProps = {
  style: STYLE_PRESET.DEFAULT
}

export { Message }