export const forecastV3FieldsToV4 = {
  "precipitation": "precipitationIntensity",
  "precipitation_type": "precipitationType",
  "temp": "temperature",
  "feels_like": "temperatureApparent",
  "dewpoint": "dewPoint",
  "wind_speed": "windSpeed",
  "wind_gust": "windGust",
  "baro_pressure": "seaLevelPressure",
  "visibility": "visibility",
  "humidity": "humidity",
  "wind_direction": "windDirection",
  "sunrise": "sunrise",
  "sunset": "sunset",
  "cloud_cover": "cloudCover",
  "cloud_ceiling": "cloudCeiling",
  "cloud_base": "cloudBase",
  "surface_shortwave_radiation": "solarGHI",
  "epa_aqi": "epaIndex",
  "china_aqi": "mepIndex",
  "pm25": "particulateMatter25",
  "pm10": "particulateMatter10",
  "o3": "pollutantO3",
  "no2": "pollutantNO2",
  "co": "pollutantCO",
  "so2": "pollutantSO2",
  "hail_binary": "hailBinary"
}

export const FORECAST_KEYS = {
  "precipitationIntensity": "precipitationIntensity",
  "precipitationType": "precipitationType",
  "temperature": "temperature",
  "temperatureApparent": "temperatureApparent",
  "dewPoint": "dewPoint",
  "windSpeed": "windSpeed",
  "windGust": "windGust",
  "seaLevelPressure": "seaLevelPressure",
  "visibility": "visibility",
  "humidity": "humidity",
  "windDirection": "windDirection",
  "sunrise": "sunrise",
  "sunset": "sunset",
  "cloudCover": "cloudCover",
  "cloudCeiling": "cloudCeiling",
  "cloudBase": "cloudBase",
  "solarGHI": "solarGHI",
  "epaIndex": "epaIndex",
  "mepIndex": "mepIndex",
  "particulateMatter25": "particulateMatter25",
  "particulateMatter10": "particulateMatter10",
  "pollutantO3": "pollutantO3",
  "pollutantNO2": "pollutantNO2",
  "pollutantCO": "pollutantCO",
  "pollutantSO2": "pollutantSO2",
  "hailBinary": "hailBinary",
  "startTime": "startTime",
}