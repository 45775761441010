import React from 'react';
import styles from './CronInput.module.scss';
import classNames from 'classnames';
import { TimePicker } from 'antd';
import moment from 'moment'
import _ from 'lodash';

const DAYS = [
  { value: '0', name: 'M' },
  { value: '1', name: 'T' },
  { value: '2', name: 'W' },
  { value: '3', name: 'T' },
  { value: '4', name: 'F' },
  { value: '5', name: 'S' },
  { value: '6', name: 'S' },
]

const format = 'HH:mm';

const CronInput = React.memo(({ onChange, error, defaultValue }) => {
  let defaultDays = [];
  let defaultTime = null;

  if (defaultValue) {
    let [minutes, hours, , , days] = defaultValue.split(' ')

    defaultDays = days.split(',');
    defaultTime = {
      minutes,
      hours
    }
  }

  const [days, setDays] = React.useState(defaultDays);
  const [time, setTime] = React.useState(defaultTime);

  React.useEffect(() => {
    if (days.length === 0 || !time) {
      return onChange('')
    }

    let { minutes, hours } = time;
    if (_.isUndefined(hours)) {
      return onChange('')
    }

    let cron = `${minutes} ${hours} * * ${days.join(',')}`;

    onChange(cron)
  }, [days, time, onChange]);

  const onDayClick = React.useCallback((e) => {
    let { target } = e;
    let value = target.dataset.value;
    if (days.includes(value)) {
      setDays(days.filter(i => i !== value))
    } else {
      setDays(old => [...old, value])
    }
  }, [setDays, days]);

  const onTimeSelected = React.useCallback((timeMoment) => {
    if (!timeMoment) {
      return setTime(null)
    }

    let hours = timeMoment.hour();
    let minutes = timeMoment.minute();

    setTime({ hours, minutes })
  }, [setTime])

  return (
    <div className={styles.wrapper}>
      <h4>Select days</h4>
      <div className={styles.days}>
        {DAYS.map(({ value, name }) => {
          let isActive = days.includes(value)
          return (
            <div
              key={`${value}-${name}`}
              onClick={onDayClick}
              data-value={value}
              className={classNames(styles.day, isActive && styles.active)}>
              {name}
            </div>
          )
        }
        )}
      </div>
      <h4>Select hours</h4>
      <TimePicker
        defaultValue={defaultTime && time && moment(`${time.hours}:${time.minutes}0`, format)}
        onChange={onTimeSelected}
        minuteStep={15}
        format={format} />

      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
})

CronInput.defaultProps = {
  defaultValue: null,
  onChange: () => { }
}

export { CronInput };