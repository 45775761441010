import React from 'react';
import styles from './InsightsViaEmail-editor.module.scss';
import { Button } from '../core/buttons/Button';
import { INPUT_TYPE, STYLE_PRESET, BUTTON_TYPE } from '../../consts';
import { Input } from '../core/input/Input';
import { MultiSelect } from '../core/multiSelect/MultiSelect';
import { Select } from '../core/select/Select';
import { CronInput } from '../core/cronInput/CronInput';
import { Loader } from '../common/loader/Loader';

const InsightsViaEmailEditorComponent = React.memo(({ lang, task: currentTask, dashboardsList, groupsList, onFinish, createOrUpdateTask }) => {
  const isNew = !currentTask.id;
  const [task, setTask] = React.useState(currentTask)
  const [loadingState, setLoadingState] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  const checkTask = React.useCallback(() => {
    let errors = {};
    let { title, cron, groupId, dashboards } = task;

    if (!title || title === '') {
      errors.title = 'Title is required';
    }

    if (!cron || cron === null) {
      errors.cron = "Task time is required - at least one day"
    }

    if (!groupId || groupId === '') {
      errors.groupId = 'Group is required - please select one';
    }

    if (!dashboards || dashboards.length === 0) {
      errors.dashboards = 'Select at least one dashboard to be sent'
    }

    return errors;
  }, [task])

  const onSubmit = React.useCallback(async e => {
    e.preventDefault();
    setErrors({});
    setLoadingState(true)

    let errorsMessage = checkTask();

    if (Object.keys(errorsMessage).length > 0) {
      setLoadingState(false);
      return setErrors(errorsMessage);
    }

    try {
      await createOrUpdateTask(task);
      onFinish();
    } catch (e) {
      console.log(e);
    }

    setLoadingState(false)

  }, [setLoadingState, createOrUpdateTask, task, setErrors, checkTask, onFinish])

  const onSelectDashboards = React.useCallback(dashboards => {
    setTask(oldTask => Object.assign({}, oldTask, { dashboards }))
  }, [setTask])

  const setGroup = React.useCallback(groupId => {
    setTask(oldTask => Object.assign({}, oldTask, { groupId }))
  }, [setTask])

  const onChange = React.useCallback(({ name, value }) => {
    setTask(old => Object.assign({}, old, { [name]: value }))
  }, [])

  const onSetTime = React.useCallback((cron) => {
    setTask(old => Object.assign({}, old, { cron }))
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <label>{lang.TITLE_PLACEHOLDER}</label>
      <Input
        type={INPUT_TYPE.TEXT}
        name="title"
        value={task.title}
        onChange={onChange}
        placeholder={lang.TITLE_PLACEHOLDER}
        error={errors.title} />

      <label>{lang.SELECT_DASHBOARDS}</label>
      <MultiSelect
        values={dashboardsList}
        defaultValue={task.dashboards}
        placeholder={lang.SELECT_DASHBOARDS_PLACEHOLDER}
        error={errors.dashboards}
        onChange={onSelectDashboards} />

      <label>{lang.SELECT_GROUPS}</label>
      <Select
        placeholder={lang.SELECT_GROUPS_PLACEHOLDER}
        defaultValue={task.groupId}
        values={groupsList}
        onChange={setGroup}
        error={errors.groupId} />

      <label>{lang.SELECT_TIME}</label>
      <CronInput
        defaultValue={task.cron}
        onChange={onSetTime}
        error={errors.cron} />

      <div className={styles.buttons}>
        {!loadingState && (<>
          <Button buttonStyle={STYLE_PRESET.DANGER} onClick={onFinish} >Cancel</Button>
          <Button buttonStyle={STYLE_PRESET.GOOD} type={BUTTON_TYPE.SUBMIT}>{isNew ? 'Create' : 'Update'}</Button>
        </>)}
        {loadingState && <Loader />}
      </div>

    </form>
  )
})

InsightsViaEmailEditorComponent.defaultProps = {
  lang: {},
  id: '',
  title: '',
  task: {},
  onFinish: () => { },
  dashboardsList: [],
  groupsList: [],
  createOrUpdateTask: () => { },
}

export { InsightsViaEmailEditorComponent };