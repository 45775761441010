import React from 'react';
import 'antd/dist/antd.css';
import styles from './App.module.scss'
import { ConnectedRouter } from 'connected-react-router';
import { Router } from './App.router';
import UserService from '../../services/UserService';

const App = React.memo(({ history }) => {
  const [loading, setLoadingState] = React.useState(true);

  const init = React.useCallback(async () => {
    await UserService.init();
    setLoadingState(false)
  }, [])

  React.useEffect(() => {
    init();
  }, [init])

  return (
    <>
      <ConnectedRouter history={history}>
        <div className={styles.root}>
          {!loading && <Router />}
        </div>
      </ConnectedRouter>
    </>
  )
})

export { App };