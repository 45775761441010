import { UPDATE_ORGANIZATION_DATA } from "."
import { isHaveToFetchNewData, getDataByKey } from "../selectors/OrganizationData.selector";
import { DATA_KEYS } from "../../consts/OrganizationDate";
import InsightsViaEmailAPI from "../../api/InsightsViaEmailAPI";
import UserService from "../../services/UserService";
import HypercastAPI from "../../api/HypercastAPI";
import moment from 'moment-timezone';
import async from 'async';


export const getOrganizationTasks = () => async (dispatch, getState) => {
  let currentData = getDataByKey(DATA_KEYS.TASKS, getState());

  if (currentData && !isHaveToFetchNewData(currentData)) {
    return false
  }

  let { data } = await InsightsViaEmailAPI.getAllTasksByOrgId(UserService.orgId)
  await dispatch(getOrganizationDashboards());
  await dispatch(getOrganizationGroups());
  await dispatch(getOrganizationInsights());

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.TASKS,
      data: data.tasks
    }
  })
}

export const getOrganizationDashboards = () => async (dispatch, getState) => {
  let currentData = getDataByKey(DATA_KEYS.DASHBOARDS, getState());

  if (currentData && !isHaveToFetchNewData(currentData)) {
    return false
  }

  let dashboardsRes = await HypercastAPI.getDashboardsList(UserService.orgId);

  let dashboards = dashboardsRes.data;
  let dashboardsById = {};
  if (dashboards) {
    dashboards.forEach(dashboard => dashboardsById[dashboard.id] = dashboard);
  }

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.DASHBOARDS,
      data: {
        dashboards,
        dashboardsById
      }
    }
  })
}

export const getOrganizationInsights = () => async (dispatch, getState) => {
  let currentData = getDataByKey(DATA_KEYS.INSIGHTS, getState());

  if (currentData && !isHaveToFetchNewData(currentData)) {
    return false
  }



  let { data, error, raw } = await HypercastAPI.getInsights();

  if (error) {
    console.log('Fail to fetch org insights')
    console.log('insightsListRes', data);
    return { success: false };
  }

  let insights = []

  insights = insights.concat(data.insights);

  let leftRequests = Math.round((raw.meta.totalItems - 100) / 100)

  if (leftRequests > 0) {
    await async.timesLimit(leftRequests, 4, async (i, cb) => {
      let { data, error } = await HypercastAPI.getInsights((i + 1) * 100)

      if (error) {
        throw new Error(data.message)
      }

      insights = insights.concat(data.insights);

      cb && cb();
    })
  }

  insights.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
    return 0;
  })

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.INSIGHTS,
      data: {
        insights
      }
    }
  })
}

export const getOrganizationGroups = () => async (dispatch, getState) => {
  let currentData = getDataByKey(DATA_KEYS.GROUPS, getState());

  if (currentData && !isHaveToFetchNewData(currentData)) {
    return false
  }

  let groupsRes = await HypercastAPI.getOrganizationGroups(UserService.orgId)

  let groups = groupsRes.data.groups;
  let groupsById = {};
  groups.forEach(group => groupsById[group.id] = group);

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.GROUPS,
      data: {
        groups,
        groupsById
      }
    }
  })
}

const createTask = task => async (dispatch, getState) => {
  let { data, error } = await InsightsViaEmailAPI.createNewTask(task);

  if (error) {
    throw new Error(error);
  }

  let { taskId } = data;

  task.id = taskId;

  let tasks = getDataByKey(DATA_KEYS.TASKS, getState());

  let newTasks = [...tasks.data, task]

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.TASKS,
      data: newTasks
    }
  })
}

export const toggleTask = async taskId => {
  let { error } = await InsightsViaEmailAPI.toggleTask(taskId)

  if (error) {
    throw new Error(error);
  }
}

export const deleteTask = taskId => async (dispatch, getState) => {
  let { error } = await InsightsViaEmailAPI.deleteTask(taskId);

  if (error) {
    throw new Error(error);
  }

  let { data: tasks } = getDataByKey(DATA_KEYS.TASKS, getState());

  tasks = tasks.filter(({ id }) => id !== taskId);

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.TASKS,
      data: tasks
    }
  })
}

const updateTask = task => async (dispatch, getState) => {
  let { error } = await InsightsViaEmailAPI.updateTask(task);

  if (error) {
    throw new Error(error);
  }

  let { data: tasks } = getDataByKey(DATA_KEYS.TASKS, getState());

  let index = tasks.findIndex(({ id }) => id === task.id)

  if (index === -1) {
    throw new Error('Something went wrong');
  }

  tasks[index] = task;

  dispatch({
    type: UPDATE_ORGANIZATION_DATA,
    payload: {
      key: DATA_KEYS.TASKS,
      data: tasks
    }
  })
}

export const createOrUpdateTask = (task) => async dispatch => {
  let orgId = UserService.orgId;
  task.organizationId = orgId;
  task.timeZone = moment.tz.guess();

  if (task.id) {
    // Update the task
    await dispatch(updateTask(task))
    return true
  }

  await dispatch(createTask(task))
}

export const executeTask = taskId => async dispatch => {
  await InsightsViaEmailAPI.executeTask(taskId);
}