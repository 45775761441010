import React from 'react';
import styles from './InsightsViaEmail-list.module.scss';
import { Loader } from '../common/loader/Loader'
import { Message } from '../core/message/Message';
import { InsightsViaEmailList } from './InsightsViaEmail-list';
import { STYLE_PRESET } from '../../consts';
import { Popup } from '../common/popup/Popup';
import { InsightsViaEmailEditor } from './InsightsViaEmail-editor';

const InsightsViaEmailManagerComponent = React.memo(({ fetchList, tasks, loading, error, lang, lastUpdated }) => {
  const [loadingState, setLoadingState] = React.useState(loading)
  const [errorMessage, setErrorMessage] = React.useState(error)
  const [isCreateTask, setCreateTaskState] = React.useState(false)
  const [task, setTask] = React.useState({})

  const fetchData = React.useCallback(async () => {
    try {
      await fetchList();
    } catch ({ message }) {
      setErrorMessage(message)
    }

    setLoadingState(false)
  }, [fetchList])

  // Init state
  React.useEffect(() => {
    fetchData();
  }, [fetchData])

  const createNewTask = React.useCallback(() => setCreateTaskState(true), [setCreateTaskState])

  const updateTask = React.useCallback(taskId => {
    let task = tasks.find(({ id }) => id === taskId)
    setTask(task);
    setCreateTaskState(true)
  }, [tasks])

  const onCreate = React.useCallback(() => {
    setCreateTaskState(false)
  }, [setCreateTaskState])

  // Render the content base on the state
  const renderContent = React.useCallback(() => {
    // Loading state
    if (loadingState) {
      return <Loader />
    }

    if (errorMessage) {
      return <Message style={STYLE_PRESET.DANGER} >{errorMessage}</Message>
    }

    return <InsightsViaEmailList
      key={lastUpdated}
      tasks={tasks}
      updateTask={updateTask}
      createNewTask={createNewTask} />
  }, [loadingState, tasks, errorMessage, createNewTask, updateTask, lastUpdated])

  return (
    <div className={styles.root}>
      {renderContent()}
      <Popup
        title={lang.TASK_EDITOR.TITLE}
        isOpen={isCreateTask}
        onClose={onCreate}>
        <InsightsViaEmailEditor task={task} onFinish={onCreate} />
      </Popup>
    </div>
  )
})

InsightsViaEmailManagerComponent.defaultProps = {
  fetchList: () => { },
  tasks: [],
  error: '',
  loading: true,
  lang: {}
}

export { InsightsViaEmailManagerComponent };