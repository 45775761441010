import React from 'react';
import styles from './SignInFrom.module.scss';
import { Input } from '../../core/input/Input';
import { INPUT_TYPE, BUTTON_TYPE, STYLE_PRESET } from '../../../consts';
import { Button } from '../../core/buttons/Button';
import { Message } from '../../core/message/Message';
import { Loader } from '../../common/loader/Loader';

const SignInFormComponent = React.memo(({ lang, error, loading, signIn, validate }) => {
  const [credentials, setCredentials] = React.useState({});
  const [errorMessage, setError] = React.useState(error)
  const [loadingState, setLoadingState] = React.useState(loading)

  const onChange = React.useCallback(({ name, value }) => {
    setCredentials(old => Object.assign({}, old, { [name]: value }))
  }, [])

  const onSubmit = React.useCallback(async e => {
    e.preventDefault();
    setLoadingState(true)
    setError('')

    let errorMessage = validate(credentials);

    if (errorMessage) {
      setLoadingState(false);
      return setError(errorMessage)
    }

    try {
      await signIn(credentials);
    } catch (e) {
      setError(e.message)
    }

    setCredentials(old => Object.assign({}, old, { password: '' }))

    setLoadingState(false);

  }, [credentials, setError, signIn, validate])

  return (
    <form onSubmit={onSubmit} className={styles.root}>
      <Input type={INPUT_TYPE.EMAIL} name="email" onChange={onChange} placeholder={lang.EMAIL_PLACEHOLDER} />
      <Input type={INPUT_TYPE.PASSWORD} name="password" onChange={onChange} placeholder={lang.PASSWORD_PLACEHOLDER} />
      <Message style={STYLE_PRESET.DANGER}>{errorMessage}</Message>
      {loadingState && <Loader />}
      {!loadingState &&
        <Button buttonStyle={STYLE_PRESET.PRIMARY} type={BUTTON_TYPE.SUBMIT}>{lang.SIGNIN_BUTTON}</Button>
      }
    </form>
  )
})

SignInFormComponent.defaultProps = {
  loading: false
}

export { SignInFormComponent }