import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { BUTTON_TYPE, STYLE_PRESET } from '../../../consts';

const Button = React.memo(({ type, children, onClick, buttonStyle, className, disabled }) => {
  return <button
    type={type}
    disabled={disabled}
    className={classNames(styles.root, styles[buttonStyle], className)}
    onClick={onClick}>
    {children}
  </button>
})

Button.defaultProps = {
  type: BUTTON_TYPE.BUTTON,
  onClick: () => { },
  buttonStyle: STYLE_PRESET.DEFAULT,
  disabled: false
}

export { Button };