import { AUTH_LOGOUT, UPDATE_ORGANIZATION_DATA } from "../actions";

const initialState = {};

const authLogout = (state) => {
  return {};
}

const updateOrgData = (state, { key, data }) => {
  let newState = Object.assign({}, state);
  newState.lastUpdated = Date.now();

  newState[key] = {
    data,
    lastUpdated: Date.now()
  };

  return newState;
}

const actionsRepo = {
  [AUTH_LOGOUT]: authLogout,
  [UPDATE_ORGANIZATION_DATA]: updateOrgData
}

export default (state = initialState, { type, payload }) =>
  !!actionsRepo[type] ? actionsRepo[type](state, payload) : state;
