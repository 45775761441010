import React from 'react';
import styles from './Loader.module.scss';
import { Lang } from '../../../consts/Lang';

export const Loader = React.memo(() => {
  return (
    <div className={styles.spinnerWrapper}>
      <div className={styles.spinner}>
        <span></span><span></span><span></span>
      </div>
      <div className={styles.label}>{Lang.LOADING}</div>
    </div>
  )
})