import React from 'react';
import styles from './page.core.module.scss';

const Page = React.memo(({ children, header }) => {

  return (
    <div className={styles.root}>
      <h2 className={styles.header}>{header}</h2>
      <div className={styles.page}>
        {children}
      </div>
    </div>
  )
})

Page.defaultProps = {
  header: '',
}

export { Page }