import React from 'react';
import styles from './Select.module.scss';
import { Select as SelectAntd } from 'antd';

const { Option } = SelectAntd;

const Select = React.memo(({ onChange, placeholder, values, error, defaultValue }) => {
  return (
    <div className={styles.wrapper}>
      <SelectAntd
        showSearch
        defaultValue={defaultValue}
        className={styles.select}
        placeholder={placeholder}
        onChange={onChange}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {values.map(({ id, name }) =>
          <Option key={id} value={id}>{name}</Option>
        )}
      </SelectAntd>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
})

Select.defaultProps = {
  onChange: () => { },
  placeholder: '',
  values: [],
  defaultValue: '',
}

export { Select }