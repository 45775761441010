import React from 'react';
import styles from './timeline.module.scss';
import { Tooltip } from 'antd';
import moment from 'moment';

const INSIGHTS_SEVERITY_COLORS = {
  unknown: '#b6c0cc',
  minor: '#ffff42',
  moderate: '#f67703',
  severe: '#ec002c',
  extreme: '#b80c0a',
}

const Timeline = React.memo(function TimelineWrapper({ id, insight, timeline }) {
  if (timeline.length === 0) return null

  if (timeline.length === 1 && !timeline[0].state) return null

  let insightNameDisplayed = false;

  const renderTimelineName = ({ state }) => {
    if (state && !insightNameDisplayed) {
      insightNameDisplayed = true;
      return <div className={styles.timelineName} data-insight={insight.name} />
    }
  }

  return (
    <div className={styles.timeline}>
      {timeline.map(({ state, duration, startTime, endTime }, index) => {
        if (!state) {
          return <div style={{ flexGrow: duration }} key={`${id}-${index}`} />
        }

        let title = `${moment(startTime).format('DD/MM h:mm A')} - ${moment(endTime).format('DD/MM h:mm A')}`;

        return (
          <>
            {renderTimelineName({ state })}
            <Tooltip title={title} key={`${id}-${index}`}>
              <div style={{ flexGrow: duration, background: state && INSIGHTS_SEVERITY_COLORS[insight.severity] }} className={state && styles.active} />
            </Tooltip>
          </>
        )
      })}
    </div>
  )
})

Timeline.defaultProps = {

}

export { Timeline }
