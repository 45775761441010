import { AUTH_LOGIN, AUTH_LOGOUT } from "."
import UserService from "../../services/UserService"

export const signIn = (credentials) => async dispatch => {
  let { token, user } = await UserService.signIn(credentials)

  dispatch({
    type: AUTH_LOGIN,
    payload: { token, user }
  })
}

export const logout = () => async dispatch => {
  dispatch({ type: AUTH_LOGOUT });
}