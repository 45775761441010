import { Executer, GET, POST, PATCH, DELETE } from "../utils/Executer";
import { baseURL } from "../consts/InsightsOverEmailService";

class InsightsViaEmailAPI {
  constructor() {
    this.client = new Executer({ baseURL })
  }
  async getAllTasksByOrgId(orgId) {
    return await this.client.execute(GET, `tasks?organizationId=${orgId}`)
  }

  async createNewTask(data) {
    return await this.client.execute(POST, `tasks`, data)
  }

  async updateTask(task) {
    return await this.client.execute(PATCH, `tasks/${task.id}`, task)
  }

  async toggleTask(taskId) {
    return await this.client.execute(POST, `tasks/${taskId}/toggle`)
  }

  async deleteTask(taskId) {
    return await this.client.execute(DELETE, `tasks/${taskId}`)
  }

  async executeTask(taskId) {
    return await this.client.execute(POST, `tasks/${taskId}`);
  }

  setToken(token) {
    this.client.setAccessToken(token);
  }
}

export default new InsightsViaEmailAPI();