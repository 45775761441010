import React from 'react';
import styles from './Frame.module.scss'
import { Header } from '../';

export class Frame extends React.PureComponent {
  render() {
    let { children } = this.props;
    return (
      <div className={styles.frame}>
        <Header />
        <div className={styles.mainPage}>
          {children}
        </div>
      </div>
    )
  }
}