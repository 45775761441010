import React from 'react';
import styles from './InsightsViaEmail-list.module.scss';
import { Button } from '../core/buttons/Button';
import { InsightsDashboards } from '../../images';
import { InsightsViaEmailListRow } from './InsightsViaEmail-row';
import { STYLE_PRESET } from '../../consts';

const InsightsViaEmailListComponent = React.memo(({ lang, tasks, createNewTask, updateTask }) => {

  if (tasks.length === 0) {
    return (
      <div className={styles.noTasks}>
        <img src={InsightsDashboards} alt={lang.NO_TASKS_TITLE} className={styles.noTasksImage} />
        <h1 className={styles.header}>{lang.NO_TASKS_TITLE}</h1>
        <p className={styles.content}>{lang.NO_TASKS_CONTENT}</p>
        <Button
          buttonStyle={STYLE_PRESET.PRIMARY}
          onClick={createNewTask}
          className={styles.cta}>
          {lang.NO_TASKS_CTA}
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.tableRoot}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Group</th>
            <th>Dashboards</th>
            <th></th>
            <th>
              <Button
                buttonStyle={STYLE_PRESET.PRIMARY}
                onClick={createNewTask}>
                {lang.CREATE_A_TASK}
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(item =>
            <InsightsViaEmailListRow key={item.id} updateTask={updateTask} {...item} />
          )}
        </tbody>
      </table>
    </div>
  )
})

InsightsViaEmailListComponent.defaultProps = {
  fetchList: () => { },
  updateTask: () => { },
  test: '',
  tasks: [],
}

export { InsightsViaEmailListComponent };