import { FORECAST_TYPES } from "../../consts/forecasts.constants";
import { METAR_CONVERSION } from "./convertors/metar.convertor";

const FORECAST_CONVERTORS = {
  [FORECAST_TYPES.METAR]: METAR_CONVERSION
}

export function forecastConvertor({ forecast, type }) {
  if (!FORECAST_CONVERTORS[type]) {
    throw new Error(`Forecast type doesn't exist (${type})`);
  }

  let intervals = FORECAST_CONVERTORS[type]({ forecast })

  let startTime = intervals[0].startTime;
  let endTime = intervals[intervals.length - 1].startTime;

  if (new Date(endTime) - new Date(startTime) < 0) {
    intervals.reverse();
    startTime = intervals[0].startTime;
    endTime = intervals[intervals.length - 1].startTime;
  }

  return {
    intervals,
    startTime,
    endTime
  }
}