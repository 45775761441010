import React from 'react';
import styles from './Popup.module.scss'
import { XIcon } from '../../../images';

const Popup = React.memo(({ title, isOpen, onClose, children }) => {
  if (!isOpen) {
    return false
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          <XIcon onClick={onClose} className={styles.close} />
        </div>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  )
})

Popup.defaultProps = {
  title: '',
  onClose: () => { },
  isOpen: false
}

export { Popup }