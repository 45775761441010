import { connect } from 'react-redux';
import { InsightsViaEmailListRowComponent } from './InsightsViaEmail-row.component';
import { getCurrentDataByKey } from '../../store/selectors/OrganizationData.selector';
import { DATA_KEYS } from '../../consts/OrganizationDate';
import { executeTask, toggleTask, deleteTask } from '../../store/actions/OrganizationData.actions';

const mapStateToProps = (state) => ({
  dashboardsData: getCurrentDataByKey(DATA_KEYS.DASHBOARDS, state),
  groupsData: getCurrentDataByKey(DATA_KEYS.GROUPS, state)
})

const mapDispatchToProps = (dispatch) => ({
  executeTask: async (taskId) => await dispatch(executeTask(taskId)),
  toggleTask: async (taskId) => await toggleTask(taskId),
  deleteTask: async (taskId) => await dispatch(deleteTask(taskId)),
})

export const InsightsViaEmailListRow = connect(mapStateToProps, mapDispatchToProps)(InsightsViaEmailListRowComponent)