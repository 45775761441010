import jwtDecode from 'jwt-decode';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE_USER } from "../actions";

const initialState = {
  token: "",
  tokenDetails: {},
  user: {}
};

const authLogin = (state, { token, user }) => {
  let newState = Object.assign({}, state, { token, user })
  newState.tokenDetails = { ...jwtDecode(token) };

  return newState;
}

const updateUserDetails = (state, { user }) =>
  Object.assign({}, state, { user })


const authLogout = (state) => {
  return {
    token: "",
    tokenDetails: {},
    user: {}
  };
}

const actionsRepo = {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE_USER]: updateUserDetails
}

export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
