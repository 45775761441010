import { connect } from 'react-redux';
import { InsightsViaEmailManagerComponent } from './InsightsViaEmail-manager.component';
import { getOrganizationTasks } from '../../store/actions/OrganizationData.actions';
import { getCurrentDataByKey } from '../../store/selectors/OrganizationData.selector';
import { DATA_KEYS } from '../../consts/OrganizationDate';

const mapStateToProps = (state) => ({
  lang: state.lang.INSIGHTS_VIA_EMAIL,
  tasks: getCurrentDataByKey(DATA_KEYS.TASKS, state) || [],
  lastUpdated: Date.now()
})

const mapDispatchToProps = dispatch => ({
  fetchList: async () => await dispatch(getOrganizationTasks())

})

export const InsightsViaEmailManager = connect(mapStateToProps, mapDispatchToProps)(InsightsViaEmailManagerComponent)