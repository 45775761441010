import { connect } from "react-redux";
import { DATA_KEYS } from "../../consts/OrganizationDate";
import { getOrganizationInsights, getOrganizationDashboards } from "../../store/actions/OrganizationData.actions";
import { getCurrentDataByKey } from "../../store/selectors/OrganizationData.selector";
import { ScenarioDashboardComponent } from "./scenario-dashboard.component";

const mapStateToProps = (state) => ({
  insights: getCurrentDataByKey(DATA_KEYS.INSIGHTS, state)?.insights || [],
  dashboards: getCurrentDataByKey(DATA_KEYS.DASHBOARDS, state)?.dashboards || [],
  lastUpdated: Date.now(),
})

export const ScenarioDashboard = connect(mapStateToProps, { getOrganizationInsights, getOrganizationDashboards })(ScenarioDashboardComponent);