import React from 'react';
import { Timeline } from '../timeline';
import styles from './timeline-wrapper.module.scss';
import moment from 'moment';

const TimelineWrapper = React.memo(function TimelineWrapper({ timelines, startTime, endTime }) {
  if (!timelines.length) return null

  let startDate = moment(startTime).startOf('day')
  let endDate = moment(endTime).endOf('day')
  let numberOfDays = startTime ? endDate.diff(startDate, 'days') + 1 : 0;
  let paddingDays = 5 - numberOfDays;
  numberOfDays += paddingDays;
  endDate.add(paddingDays - 1, 'day');

  let startDateDiffPerc = moment(startTime).diff(startDate, 'hours') / (numberOfDays * 24)
  let endDateDiffPerc = endDate.diff(moment(endTime), 'hours') / (numberOfDays * 24)

  let days = Array(numberOfDays).fill(1).map((v, i) => {
    let currentDate = startDate.add(1, 'day');

    return (
      <div className={styles.day} key={`day-${i}`}>
        <div className={styles.header}>
          <h4>{currentDate.format('ddd')}</h4>
          {currentDate.format('DD')}
          <div className={styles.dayTimes}>
            <span>Morning</span>
            <span>Afternoon</span>
            <span>Night</span>
          </div>
        </div>
      </div>
    )
  })

  let leftPadding = startDateDiffPerc * 100 + '%';
  let rightPadding = endDateDiffPerc * 100 + '%';

  let timelineWrapperStyle = {
    paddingLeft: leftPadding,
    paddingRight: rightPadding,
  }

  return (
    <div className={styles.root}>
      <div className={styles.dashboard}>
        <div className={styles.location}>
          <h5>JFK</h5>
        </div>
        <div className={styles.timelineWrapper} style={timelineWrapperStyle}>
          <div className={styles.shadow} style={{ left: 0, width: leftPadding }} />
          {timelines.map(t => <Timeline key={t.id} {...t} />)}
          <div className={styles.shadow} style={{ right: 0, width: rightPadding }} />
        </div>
      </div>
      <div className={styles.timetable}>
        {days}
      </div>
    </div>
  )
})

TimelineWrapper.defaultProps = {
  timelines: [],
  startTime: null,
  endTime: null,
}

export { TimelineWrapper }
