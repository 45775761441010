import React from 'react';
import styles from './MultiSelect.module.scss';
import { Select, Tag } from 'antd';

const { Option } = Select;

const MultiSelect = React.memo(({ values, defaultValue, onChange, placeholder, error }) => {
  const tagRender = React.useCallback(({ label, closable, onClose }) => {
    return (
      <Tag color='blue' closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  }, [])

  return (
    <div className={styles.wrapper}>
      <Select
        mode="multiple"
        className={styles.root}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        optionLabelProp="label"
        tagRender={tagRender}
      >
        {values.map(({ value, name }) =>
          <Option key={value} value={value} label={name}> {name} </Option>
        )}
      </Select>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
})

MultiSelect.defaultProps = {
  values: [],
  defaultValue: [],
  onChange: () => { },
  placeholder: ''
}

export { MultiSelect };