import { connect } from 'react-redux';
import { Route } from './Route.component';
import UserService from '../../services/UserService';
import { ROUTES } from '../../consts';

const mapStateToProps = (state) => {
  return {
    isAllowedRoute: !UserService.isAuthenticated,
    redirectRoute: ROUTES.SIGN_IN
  }
}

export const PrivateRoute = connect(mapStateToProps)(Route)