import React from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

const Input = React.memo(({ type, name, value, onChange, className, placeholder, error }) => {

  const innerOnChange = React.useCallback(({ target }) => {
    let { name, value } = target;

    onChange({ name, value })
  }, [onChange])

  return (
    <div className={styles.wrapper}>
      <input
        type={type}
        name={name}
        defaultValue={value}
        autoComplete="false"
        placeholder={placeholder}
        onChange={innerOnChange}
        className={classNames(
          styles.root,
          className
        )}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
})

Input.defaultProps = {
  type: 'text',
  name: '',
  value: undefined,
  defaultValue: undefined,
  placeholder: null,
  onChange: () => { },
  onClick: () => { },
  IconComponent: null,
  inputRef: null,
  className: null,
  inputClassName: null,
  isInvalid: false,
  disabled: false,
  autoFocus: false,
  error: '',
};


export { Input }