import React from 'react';
import styles from './SignIn.module.scss';
import { OB, Logo } from '../../images';
import { SignInForm } from '../../components/auth/signInForm/SignInForm';

export const SignInPage = React.memo(() => {
  return (
    <div className={styles.page}>
      <div className={styles.root}>
        <Logo className={styles.logo} />

        <img src={OB} alt="Forecast Generator" className={styles.ob} />
        <h2 style={styles.header}>Sign In</h2>

        <SignInForm />
      </div>
    </div>
  )
})