import { connect } from 'react-redux';
import { SignInFormComponent } from './SignInForm.component';
import validator from 'validator';
import { signIn } from '../../../store/actions/User.actions';

const mapStateToProps = ({ lang }) => {
  return {
    lang: lang.SIGNIN
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signIn: async (credentials) => await dispatch(signIn(credentials)),
    validate: ({ email, password }) => {
      if (!email) {
        return 'Please enter an email address';
      } else if (!validator.isEmail(email)) {
        return 'Email is invalid.';
      }

      if (!password) {
        return 'Please enter your password';
      }

      return false;
    }
  }
}

export const SignInForm = connect(mapStateToProps, mapDispatchToProps)(SignInFormComponent)