export const Lang = {
  SIGNIN: {
    HEADER: 'Sign In',
    EMAIL_PLACEHOLDER: 'Email',
    PASSWORD_PLACEHOLDER: 'Password',
    SIGNIN_BUTTON: 'Sign In'
  },
  INSIGHT_VIA_EMAIL_PAGE: {
    HEADER: 'Insights Via Email'
  },
  INSIGHTS_VIA_EMAIL: {
    LIST: {
      NO_TASKS_TITLE: 'Create you first insights dashboards via email',
      NO_TASKS_CONTENT: 'Insights Dashboard helps you keep track of all your locations and make better decisions.',
      NO_TASKS_CTA: 'Create a Task',
      CREATE_A_TASK: 'Create',
    },
    TASK_EDITOR: {
      TITLE: 'Task editor',
      TITLE_PLACEHOLDER: "Enter title",
      SELECT_DASHBOARDS_PLACEHOLDER: 'Select dashboards',
      SELECT_DASHBOARDS: 'Dashboards',
      SELECT_GROUPS: 'Group',
      SELECT_GROUPS_PLACEHOLDER: 'Select a group',
      SELECT_TIME: 'Select time'
    }
  },

  SCENARIO_DASHBOARD: {
    TITLE: 'Scenario Dashboard',
    EMPTY: {
      TITLE: 'Explore your historical forecast and be ready for future occasions',
      CONTENT: 'Scenario Dashboard helps you investigated historical occasion and make better decisions.',
    },
    UPLOAD_FORECAST: 'Upload METAR forecast',
    BUTTONS: {
      EDIT_INSIGHTS: 'Edit Insights',
      EDIT_LOCATIONS: 'Edit Locations',
      MORE: '...',
      NEW_DASHBOARD: 'New Dashboard',
    }
  },
  LOADING: 'Loading'
}