const { currentEnvironment } = require('../services/Environment');

const urls = {
  dev: "http://localhost:5001/customer-success-prod/us-central1/insightsOverEmail/api/",
  production: "https://us-central1-customer-success-prod.cloudfunctions.net/insightsOverEmail/api/",
}

const baseURL = urls[currentEnvironment()]

export {
  baseURL,
}