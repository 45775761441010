
import { OPERATORS } from '../../../consts';
import { destructionParams } from './operends-helper';

const OPERATORS_CALCULATION_FUNCTION = {
  [OPERATORS.GREATER]: (a, b) => a > b,
  [OPERATORS.GREATER_EQUAL]: (a, b) => a >= b,
  [OPERATORS.EQUAL]: (a, b) => a == b,
  [OPERATORS.LESS]: (a, b) => a < b,
  [OPERATORS.LESS_EQUAL]: (a, b) => a <= b,
}

export function mathOperator({ observation, children, operator }) {
  let { value, observationValue } = destructionParams({ observation, children })

  return OPERATORS_CALCULATION_FUNCTION[operator](observationValue, value)
}
