import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, history } from './store/store';
import { App } from './components/app';
import 'antd/dist/antd.css';
import './styles/main.scss';

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
  , document.getElementById('root'));