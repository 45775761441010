import React from 'react';
import styles from './Header.module.scss'
import { Logo } from '../../../images';
import UserService from '../../../services/UserService';

export const Header = React.memo(() => {
  return (
    <div className={styles.header}>
      <Logo className={styles.logo} />

      <div className={styles.menu}>
        <span onClick={UserService.logout} className={styles.logout}>Logout</span>
      </div>
    </div>
  )
})