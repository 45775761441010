import _ from 'lodash';

export function destructionParams({ observation, children }) {
  let [parameterObject, constObject] = children;

  let { parameter } = parameterObject.content;
  let { const: value } = constObject.content;
  let observationValue = observation.values[parameter];

  if (_.isUndefined(observationValue)) {
    throw new Error(`${parameter} - doesn't exist on the forecast`)
  }

  return {
    value,
    parameter,
    observationValue
  }
}