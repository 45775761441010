import { compose, applyMiddleware } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { saveStateMiddleware } from './state-managment'


export const middleware = ({ history }) =>
  compose(
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        saveStateMiddleware
      )
    )
  )