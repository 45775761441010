import { Lang } from "../../consts/Lang";

const initialState = Lang;

const actionsRepo = {};

export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
