import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth.reducer';
import langReducer from './lang.reducer';
import organizationDataReducer from './organizationData.reducer';

export const reducers = ({ history }) =>
  combineReducers({
    router: connectRouter(history),
    lang: langReducer,
    auth,
    organizationData: organizationDataReducer
  });