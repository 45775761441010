import React from 'react';
import { Switch, Tag } from 'antd';
import cronstrue from 'cronstrue';
import { Button } from '../core/buttons/Button';
import styles from './InsightsViaEmail-row.module.scss';
import { STYLE_PRESET } from '../../consts';
import { Loader } from '../common/loader/Loader';


const InsightsViaEmailListRowComponent = React.memo(({ id, title, dashboards, groupId, cron, dashboardsData, isActive, groupsData, executeTask, updateTask, toggleTask, deleteTask }) => {
  const [loading, setLoading] = React.useState(false)
  const [deleteLoader, setDeleteLoader] = React.useState(false)
  const [executeState, setExecuteState] = React.useState(false)

  const getDashboardsList = React.useCallback(() => {
    let { dashboardsById } = dashboardsData;

    let dashboardsNames = [];
    if (dashboards) {
      dashboards.forEach(id => dashboardsById[id] && dashboardsNames.push(dashboardsById[id].name));
    }

    if (dashboards.length === 0) {
      return <span className={styles.error}>Error! No dashboards selected</span>
    }
    return dashboardsNames.map(name => {
      return <Tag color='blue'>{name}</Tag>
    })
  }, [dashboards, dashboardsData])

  const getGroupName = React.useCallback(() => {
    let { groupsById } = groupsData;

    if (!groupsById[groupId]) {
      return <span className={styles.error}>Error! Group doesn't exist</span>
    }

    return groupsById[groupId] && groupsById[groupId].name;
  }, [groupId, groupsData])

  const execute = React.useCallback(async () => {
    setExecuteState(true)
    await executeTask(id)
  }, [id, executeTask])

  const edit = React.useCallback(() => {
    updateTask(id)
  }, [id, updateTask])

  const innerToggleTask = React.useCallback(async () => {
    setLoading(true);
    await toggleTask(id)
    setLoading(false);
  }, [id, toggleTask])

  const innerDeleteTask = React.useCallback(async () => {
    setDeleteLoader(true)
    await deleteTask(id)
  }, [id, deleteTask])

  return (
    <tr key={id}>
      <td>{title}</td>
      <td>
        {getGroupName()}
      </td>
      <td>
        {getDashboardsList()}
      </td>
      <td>
        {cronstrue.toString(`0 ${cron}`)}
      </td>
      <td>
        {!deleteLoader && (<>
          <Switch defaultChecked={isActive} loading={loading} onChange={innerToggleTask} />
          <Button onClick={edit}>Edit</Button>
          <Button buttonStyle={STYLE_PRESET.DANGER} onClick={innerDeleteTask}>Remove</Button>
          {!executeState && (<>
            <Button buttonStyle={STYLE_PRESET.GOOD} onClick={execute}>Execute</Button>
          </>)}
          {executeState && <span className={styles.executed}>Executed</span>}
        </>)}

        {deleteLoader && <Loader />}

      </td>
    </tr>
  )
})

InsightsViaEmailListRowComponent.defaultProps = {
  lang: {},
  id: '',
  title: '',
  cron: '',
  dashboards: [],
  groupId: '',
  isActive: true,
  dashboardsData: {},
  groupsData: {},
  executeTask: () => { },
  updateTask: () => { },
  toggleTask: () => { }
}

export { InsightsViaEmailListRowComponent };