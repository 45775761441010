import { connect } from 'react-redux';
import { InsightsViaEmailEditorComponent } from './InsightsViaEmail-editor.component';
import { createOrUpdateTask } from '../../store/actions/OrganizationData.actions';
import { getCurrentDataByKey } from '../../store/selectors/OrganizationData.selector';
import { DATA_KEYS } from '../../consts/OrganizationDate';

const mapStateToProps = (state) => {
  let groupsData = getCurrentDataByKey(DATA_KEYS.GROUPS, state);
  let groupsList = groupsData && groupsData.groups;

  let dashboardsData = getCurrentDataByKey(DATA_KEYS.DASHBOARDS, state);
  let dashboardsList = dashboardsData.dashboards.map(({ id: value, name }) => ({ name, value }));

  return {
    lang: state.lang.INSIGHTS_VIA_EMAIL.TASK_EDITOR,
    dashboardsList,
    groupsList
  }
}

const mapDispatchToProps = dispatch => ({
  createOrUpdateTask: task => dispatch(createOrUpdateTask(task))
})

export const InsightsViaEmailEditor = connect(mapStateToProps, mapDispatchToProps)(InsightsViaEmailEditorComponent)