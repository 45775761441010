import { FORECAST_KEYS } from "../../../consts";
import { WIND_DIRECTION_TO_DEG } from "../../../consts/forecasts.constants";
import convert from 'convert-units';

const FIELDS_MAPPING = {
  "Date": FORECAST_KEYS.startTime,
  "Temp": FORECAST_KEYS.temperature,
  "Dew Point": FORECAST_KEYS.dewPoint,
  "Humidity": FORECAST_KEYS.humidity,
  "Wind Chill": FORECAST_KEYS.windGust,
  "Wind Direction": FORECAST_KEYS.windDirection,
  "Wind Speed": FORECAST_KEYS.windSpeed,
  "Visibility": FORECAST_KEYS.visibility,
  "Weather": FORECAST_KEYS.precipitationType,
  "Clouds": FORECAST_KEYS.cloudBase,
  "Sea Level Pressure": FORECAST_KEYS.seaLevelPressure,
  "precip": FORECAST_KEYS.precipitationIntensity,
}


const F_TO_C = value => convert(value).from('F').to('C')
const MI_TO_KM = value => convert(value).from('mi').to('m') / 1000

const windDirectionConvertor = value => WIND_DIRECTION_TO_DEG[value] || null;

const FIELDS_CONVERTORS_MAPPING = {
  [FORECAST_KEYS.startTime]: value => (new Date(value)).toISOString(),
  [FORECAST_KEYS.temperature]: F_TO_C,
  [FORECAST_KEYS.dewPoint]: F_TO_C,
  [FORECAST_KEYS.visibility]: MI_TO_KM,
  [FORECAST_KEYS.windDirection]: windDirectionConvertor
}

export const METAR_CONVERSION = ({ forecast }) => {
  return forecast.map(observation => {
    console.log('observation', observation)
    let values = {}
    Object.keys(observation).forEach(key => {
      if (!FIELDS_MAPPING[key]) return false
      let obsKey = FIELDS_MAPPING[key];
      values[FIELDS_MAPPING[key]] = FIELDS_CONVERTORS_MAPPING[obsKey] ?
        FIELDS_CONVERTORS_MAPPING[obsKey](observation[key]) :
        observation[key]
    })

    return {
      [FORECAST_KEYS.startTime]: values[FORECAST_KEYS.startTime],
      values
    };
  })
}