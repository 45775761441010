const baseURL = "https://data.climacell.co";
const accountURL = "https://data.climacell.co";

const USER_SERVICE_URL = "/v4/"
const INSIGHTS_SERVICE_URL = "/insights/v1/"

export {
  baseURL,
  accountURL,
  USER_SERVICE_URL,
  INSIGHTS_SERVICE_URL
}