import { Executer, GET, POST, PATCH } from "../utils/Executer";
import { baseURL, accountURL, USER_SERVICE_URL, INSIGHTS_SERVICE_URL } from "../consts/Hypercast";

class HypercastAPI {
  constructor() {
    this.client = new Executer({ baseURL })
    this.accountClient = new Executer({ baseURL: accountURL })
    this.token = '';
  }

  async login({ email, password }) {
    return await this.accountClient.execute(POST, `${USER_SERVICE_URL}auth/sign-in`, { email, password });
  }

  async me() {
    return await this.accountClient.execute(GET, `${USER_SERVICE_URL}users/me?jwt=${this.token}`);
  }

  async getOrganizationDetails(orgId) {
    return await this.accountClient.execute(GET, `${USER_SERVICE_URL}accounts/me?jwt=${this.token}`)
  }

  async getOrganizationUsers(orgId) {
    return await this.accountClient.execute(GET, `${USER_SERVICE_URL}users?jwt=${this.token}`)
  }

  async getOrganizationGroups(orgId) {
    return await this.accountClient.execute(GET, `${USER_SERVICE_URL}groups?jwt=${this.token}`)
  }

  async getInsights(offset = 0) {
    return await this.accountClient.execute(GET, `${USER_SERVICE_URL}insights?jwt=${this.token}&limit=100&offset=${offset}`)
  }

  async getDashboardsList() {
    return await this.client.execute(GET, `${INSIGHTS_SERVICE_URL}dashboards?jwt=${this.token}`)
  }

  setToken(token) {
    this.client.setAccessToken(token);
    this.token = token;
  }
}

export default new HypercastAPI();