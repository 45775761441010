import _ from 'lodash';
import { operationCalculate } from '../operators-calculate';

export function orOperator({ observation, children }) {
  // Check valid array
  if (!_.isArray(children)) {
    throw new Error('children not array')
  }

  for (let i = 0; i < children.length; i++) {
    if (operationCalculate({
      observation,
      condition: children[i]
    })) {
      return true
    }
  }

  return false
}