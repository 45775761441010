export const FORECAST_TYPES = {
  METAR: 'METAR'
}

export const WIND_DIRECTION_TO_DEG = {
  "N": 0,
  "NNE": 22,
  "NE": 44,
  "ENE": 67,
  "E": 90,
  "ESE": 112,
  "SE": 134,
  "SSE": 157,
  "S": 180,
  "SSW": 202,
  "SW": 224,
  "WSW": 247,
  "W": 269,
  "WNW": 292,
  "NW": 314,
  "NNW": 337,
}