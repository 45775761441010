import { OPERATORS } from "../../consts";
import { andOperator, isNullOperator, mathOperator, orOperator } from "./operators-calculators"

export const OPERATIONS_CALCULATIONS = {
  // Conjunctions 
  [OPERATORS.AND]: andOperator,
  [OPERATORS.OR]: orOperator,

  // Operands
  [OPERATORS.GREATER_EQUAL]: mathOperator,
  [OPERATORS.GREATER]: mathOperator,
  [OPERATORS.EQUAL]: mathOperator,
  [OPERATORS.LESS]: mathOperator,
  [OPERATORS.LESS_EQUAL]: mathOperator,

  [OPERATORS.IS_NULL]: isNullOperator,
}

export function operationCalculate({ observation, condition }) {
  let { content, children } = condition;
  let { operator } = content;

  if (!OPERATIONS_CALCULATIONS[operator]) {
    throw new Error(`${operator} not known`)
  }

  return OPERATIONS_CALCULATIONS[operator]({ observation, children, operator })
}